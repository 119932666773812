// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

// Images
import bgFront from "assets/images/bg-presentation.avif";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Welcome To
                    <br />
                    Devesh Textiles
                  </>
                }
                description=""
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="Get the best products at best prices with excellent services."
                action={{
                  type: "internal",
                  route: "/about_us",
                  label: "Know More",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <MKTypography variant="h2" fontWeight="bold">
                About Us
              </MKTypography>
              <br />
              <MKTypography variant="body1" color="text">
                For over 50 years, Devesh Silk Mills has been a leading
                manufacturer, trader, and exporter of high-quality ethnic
                fabrics in India, Bangladesh, and the UAE. At Devesh Silk Mills,
                our mission is to create and deliver high-quality ethnic fabrics
                that are a testament to our commitment to excellence.
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
