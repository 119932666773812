// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Fade } from "react-awesome-reveal";

// Images
import bgImage from "assets/images/loading.jpg";

function Loading() {
  return (
    <>
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          // opacity:0.9,
          // filter: "brightness(85%)",
        }}
      >
        <Container
          id="main_bg"
          sx={{ opacity: 0.7, filter: "brightness(85%)" }}
        >
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <Fade>
              <MKTypography
                variant="h1"
                color="white"
                fontWeight="bold"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["20xl"],
                  },
                  textAlign: "center",
                })}
              >
                Welcome To
                <br />
                Devesh Silk Mills
              </MKTypography>
            </Fade>
            <Fade>
              <MKTypography
                variant="body1"
                color="white"
                fontWeight="bold"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                Fashioning Possibilities
              </MKTypography>
            </Fade>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Loading;
