// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Images
import logo from "assets/images/logo_dark-removebg-preview.png";

export default {
  brand: {
    name: "Devesh Silk Mills",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/about_us" },
        // { name: "freebies", href: "https://www.creative-tim.com/templates/free" },
        // { name: "premium tools", href: "https://www.creative-tim.com/templates/premium" },
        { name: "blog", href: "/our_products" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "illustrations", href: "/our_products" },
        { name: "bits & snippets", href: "/our_products" },
        { name: "affiliate program", href: "/our_products" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/contact_us" },
        { name: "knowledge center", href: "/contact_us" },
        { name: "custom development", href: "/contact_us" },
        { name: "sponsorships", href: "/contact_us" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "/" },
        { name: "privacy policy", href: "/" },
        { name: "licenses", href: "/" },
      ],
    },
  ],
};
