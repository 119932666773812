// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import DefaultNavbar from "./components/Navbars/DefaultNavbar";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import ContactUs from "./sections/contactUs";

// Presentation page sections
import Information from "pages/Presentation/sections/Information";
import Pages from "pages/Presentation/sections/Pages";
import Information2 from "./sections/Information2";
import { Fade } from "react-awesome-reveal";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg_img_3_1.jpg";
import darkLogo from "assets/images/logo_dark-removebg-preview.png";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        brand={darkLogo}
        routes={routes}
        // transparent
        black
        // sticky
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          // opacity:0.9,
          // filter: "brightness(85%)",
        }}
      >
        <Container
          id="main_bg"
          sx={{ opacity: 0.9, filter: "brightness(85%)" }}
        >
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <Fade>
              <MKTypography
                variant="h1"
                color="white"
                fontWeight="bold"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["20xl"],
                  },
                })}
              >
                {/* <HighlightedText> */}
                Devesh Silk Mills
                {/* </HighlightedText> */}
              </MKTypography>
            </Fade>
            <Fade>
              <MKTypography
                variant="body1"
                color="white"
                fontWeight="bold"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                Pioneering the industry of fabrics.
                <br />
                <b>Located In Mumbai</b>
              </MKTypography>
            </Fade>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Fade direction={"right"}>
          <Information />
        </Fade>
        <Pages />
        <Information2 />
      </Card>
      <div id="contact_us">
        <ContactUs></ContactUs>
      </div>
    </>
  );
}

export default Presentation;
