import cotton from "assets/images/cotton.jpg";
import jacquard from "assets/images/jacquard.webp";
import polyester from "assets/images/what_we_do.avif";
import brasso from "assets/images/brasso.png";
import embroidery from "assets/images/embroidery.png";
import digital_print from "assets/images/digital_print.jpg";

export default [
  {
    image: cotton,
    name: "COTTON",
    route: "/our_products#cotton",
  },
  {
    image: polyester,
    name: "POLYESTER",
    route: "/our_products#polyester",
  },
  {
    image: embroidery,
    name: "EMBROIDERY",
    route: "/our_products#embroidery",
  },
  {
    image: digital_print,
    name: "DIGITAL PRINT",
    route: "/our_products#digital_print",
  },

  {
    image: jacquard,
    name: "JACQUARD",
    route: "/our_products#jacquard",
  },

  {
    image: brasso,
    name: "BRASSO",
    route: "/our_products#brasso",
  },
];
