import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Presentation from "pages/Presentation/index";
import ContactUs from "pages/Presentation/sections/contactUs";
import AboutUs from "pages/AboutUs";
import Products from "pages/OurProducts";
import Loading from "pages/Loading";
import FashionIcon from "pages/Fashion_Inspiration";
import routes from "routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { Analytics } from "@vercel/analytics/react";

function WrappedComponent() {
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadedBefore, setHasLoadedBefore] = useState(false);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    sessionStorage.getItem("shown")
      ? setHasLoadedBefore(true)
      : setHasLoadedBefore(false);
    if (!hasLoadedBefore) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
        setHasLoadedBefore(true);
        sessionStorage.setItem("shown", true);
      }, 1800);
      return () => clearTimeout(timer); // Clean up on component unmount
    }
  }, [pathname, hasLoadedBefore]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });
  if (isLoading && !hasLoadedBefore) {
    console.log(pathname);
    return (
      <Fade>
        <Loading />
      </Fade>
    );
  }

  return (
    <>
      <CssBaseline />
      <Analytics />
      <Routes>
        {getRoutes(routes)}
        <Route path="/presentation" element={<Presentation />} />
        <Route path="#contact_us" element={<ContactUs />} />
        <Route path="/our_products" element={<Products />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/fashion_inspiration" element={<FashionIcon />} />
        <Route path="*" element={<Navigate to="/presentation" />} />
      </Routes>
    </>
  );
}

export default function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <WrappedComponent />
      </ThemeProvider>
    </Router>
  );
}
