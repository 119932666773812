// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

function ContactUs() {
  return (
    <>
      <Grid
        container
        sx={{ mt: { xs: "20px", lg: "10px" }, justifyContent: "center" }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            container
            xs={12}
            lg={6}
            alignItems="flex-end"
            sx={{ mt: "30px" }}
          >
            <Card
              sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: "30px",
                mb: 4,
                backgroundColor: ({
                  palette: { white },
                  functions: { rgba },
                }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                width: "600",
                height: "500",
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1476.9835119813972!2d73.03718482354216!3d19.27079115692705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bd007e7edefb%3A0xb26757d0cad57bff!2sDevesh%20Silk%20Mills!5e0!3m2!1sen!2sin!4v1709901395049!5m2!1sen!2sin"
                width="550"
                height="450"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Card>
            {/* <Grid container spacing={2}> */}
            <MKBox maxWidth="550">
              {/* <Card maxWidth="550"> */}
              <MKTypography
                variant="body2"
                fontSize="70%"
                color="text"
                sx={{ mb: "10px", ml: "30px", pr: "20px" }}
              >
                <b>Our Addresses: </b>
                <br />
                <ul>
                  <li>
                    <b>Bhiwandi Office:{"  "}</b>
                    A-101, First Floor, Manilaxmi Complex, behind Maratha Punjab
                    Hotel, next to Gupta Compound, Anjurphata, Bhiwandi.
                    <br />
                    Phone: <b>7021426030</b>
                  </li>
                  <li>
                    <b>Marine Lines Office: </b>
                    Office no. 1, first floor, 95/97 building, Cavel Street,
                    Gaiwadi, Kalbadevi, Marine Lines-02.
                    <br />
                    Phone: <b>9324324499</b>
                  </li>
                </ul>
              </MKTypography>
              {/* </Card> */}
            </MKBox>
            {/* </Grid> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={3}
            >
              <MKBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={2}
                mt={-3}
              >
                <MKTypography variant="h3" color="white">
                  Contact us
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="text" mb={3}>
                  For further eqnuires, please contact us using our contact
                  form.
                </MKTypography>
                <MKBox
                  width="100%"
                  component="form"
                  method="post"
                  autoComplete="on"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.target);
                    const data = Object.fromEntries(formData);
                    const response = await fetch("/api/index", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify(data),
                    });
                    if (response.ok) {
                      event.target.reset();
                      console.log("Sent");
                    } else {
                      console.log("Failed");
                    }
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        name="name"
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        name="email"
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        name="phone"
                        variant="standard"
                        label="Phone Number"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKInput
                        name="message"
                        variant="standard"
                        label="How can we help you?"
                        placeholder="Describe your requirements and we will do our best to meet them."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent="center"
                    xs={12}
                    mt={5}
                    mb={2}
                  >
                    <MKButton type="submit" variant="gradient" color="info">
                      Enquire
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
