// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import image1 from "assets/images/manufacturing.png";
import image2 from "assets/images/best_price.png";
import image3 from "assets/images/colab.png";

function InfoCard({ position }) {
  return (
    <Grid
      container
      spacing={3}
      style={{ display: "flex", alignItems: "stretch" }}
    >
      {/* First Card */}
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ mt: 3, height: "100%" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: -6 }}>
            <MKBox pt={2} pb={1} px={2}>
              <MKBox
                component="img"
                src={image1}
                alt={"name"}
                height="250px"
                width="300px"
                borderRadius="md"
                shadow="lg"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: { xs: 3, lg: 0 } }}>
            {" "}
            {/* Add margin-top for smaller screens */}
            <MKBox
              pt={{ xs: 1, lg: 2.5 }}
              pb={2.5}
              pr={4}
              pl={{ xs: 4, lg: 1 }}
              lineHeight={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%;",
              }}
            >
              <MKTypography variant="h3">
                Rapid Manufacturing And Delivery
              </MKTypography>
              <MKTypography variant="body2" color="text">
                Our product delivery time is the fastest-in-class for
                make-to-order goods. We also warehouse ready-for-delivery goods.
              </MKTypography>
              <MKTypography variant="h6" color={position.color} mb={1}>
                <a href="/about_us">Know More</a>
              </MKTypography>
            </MKBox>
          </Grid>
        </Card>
      </Grid>

      {/* Second Card */}
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ mt: 3, height: "100%" }} length="100%">
          <Grid item xs={12} md={12} lg={12} sx={{ mt: -6 }}>
            <MKBox width="100%" pt={2} pb={1} px={2}>
              <MKBox
                component="img"
                src={image2}
                alt={"name"}
                height="250px"
                width="300px"
                borderRadius="md"
                shadow="lg"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: { xs: 3, lg: 0 } }}>
            {" "}
            {/* Add margin-top for smaller screens */}
            <MKBox
              pt={{ xs: 1, lg: 2.5 }}
              pb={2.5}
              pr={4}
              pl={{ xs: 4, lg: 1 }}
              lineHeight={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%;",
              }}
            >
              <MKTypography variant="h3">
                Competitive Fabric Pricing
              </MKTypography>
              <MKTypography variant="body2" color="text">
                Our best-in-class manufacturing set up has helped us provide
                aggressive pricing to our clients.
              </MKTypography>
              <MKTypography variant="h6" color={position.color} mb={1}>
                <a href="/about_us">Know More</a>
              </MKTypography>
            </MKBox>
          </Grid>
        </Card>
      </Grid>

      {/* Third Card */}
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ mt: 3, height: "100%" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: -6 }}>
            <MKBox width="100%" pt={2} pb={1} px={2}>
              <MKBox
                component="img"
                src={image3}
                alt={"name"}
                height="250px"
                width="300px"
                borderRadius="md"
                shadow="lg"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: { xs: 3, lg: 0 } }}>
            {" "}
            {/* Add margin-top for smaller screens */}
            <MKBox
              pt={{ xs: 1, lg: 2.5 }}
              pb={2.5}
              pr={4}
              pl={{ xs: 4, lg: 1 }}
              lineHeight={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%;",
              }}
            >
              <MKTypography variant="h3">
                Collaborative Innovation For Ethnic Fabrics
              </MKTypography>
              <MKTypography variant="body2" color="text">
                A top-down collaborative approach to fulfill the exact needs and
                wishes of our clientele in terms of quality and designing of the
                fabrics.
              </MKTypography>
              <MKTypography
                href="/about_us"
                variant="h6"
                color={position.color}
                mb={1}
              >
                <a href="/about_us">Know More</a>
              </MKTypography>
            </MKBox>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the HorizontalTeamCard
InfoCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default InfoCard;
