// @mui material components
import Icon from "@mui/material/Icon";

const routes = [
  {
    name: "home",
    icon: <Icon>dashboard</Icon>,
    href: "/presentation",
  },
  {
    name: "About Us",
    icon: <Icon>dashboard</Icon>,
    href: "/about_us",
  },
  {
    name: "Our Products",
    icon: <Icon>view_day</Icon>,
    href: "/our_products",
  },
  {
    name: "Fashion Inspiration",
    icon: <Icon>view_day</Icon>,
    href: "/fashion_inspiration",
  },

  {
    name: "Contact Us",
    icon: <Icon>dashboard</Icon>,
    href: "/presentation#contact_us",
  },
];

export default routes;
