// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import MKTypography from "components/MKTypography";
import InfoCard from "pages/Presentation/sections/infoCard";

function Information2() {
  return (
    <MKBox component="section" py={12}>
      <MKBox
        top="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        pb={{ xs: 2, lg: 6 }}
      >
        <MKTypography variant="h2" fontWeight="bold" mb={1}>
          Our Strengths
        </MKTypography>
      </MKBox>
      <InfoCard position={{ color: "info", label: "UI Designer" }} />
    </MKBox>
  );
}

export default Information2;
