/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/pagesData";

function Pages() {
  const renderData = data.map(({ image, name, route }) => (
    <Grid item xs={12} md={4} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <Link to={route}>
        <ExampleCard
          image={image}
          name={name}
          display="grid"
          minHeight="auto"
        />
      </Link>
    </Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <MKBox
        top="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        pb={{ xs: 2, lg: 6 }}
      >
        <MKTypography variant="h2" fontWeight="bold" mb={1}>
          Experience Our Products
        </MKTypography>
      </MKBox>
      <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
        <Grid container spacing={3} id="abaca2  ">
          {renderData}
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Pages;
