// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import Card from "@mui/material/Card";
import { Fade } from "react-awesome-reveal";

function Pages() {
  return (
    <MKBox component="section" py={6}>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <Card
                sx={{
                  p: 2,
                  mx: { xs: 2, lg: 3 },
                  mt: -8,
                  mb: 4,
                  boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  width: "330px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MKTypography
                  variant="h2"
                  fontWeight="bold"
                  mb={1}
                  align="center"
                >
                  Rapid Manufacturing And Delivery
                </MKTypography>
              </Card>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Fade direction={"right"}>
              <MKTypography variant="body1" color="text.primary">
                Our company prides itself on providing the fastest manufacturing
                and delivery time in the industry. We understand the importance
                of efficiency and promptness in today&apos;s fast-paced business
                world, which is why we strive to exceed our clients&apos;
                expectations with our lightning-fast production times.
                <br />
                Our state-of-the-art manufacturing operations is supported with
                latest technology and machinery. From the moment we receive your
                order, we work tirelessly to ensure that it is manufactured and
                shipped to you in the shortest possible time. Our team of
                skilled professionals is dedicated to delivering your products
                on time, every time, without compromising on quality or
                accuracy.
              </MKTypography>
            </Fade>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="200px" pb={{ xs: 2, lg: 6 }}>
              <Card
                sx={{
                  p: 2,
                  mx: { xs: 2, lg: 3 },
                  mt: -8,
                  mb: 4,
                  boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  width: "330px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="h2"
                  fontWeight="bold"
                  mb={1}
                  align="center"
                >
                  COMPETITIVE FABRIC PRICING
                </MKTypography>
              </Card>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Fade direction={"right"}>
              <MKTypography variant="body1" color="text.primary">
                Our company is dedicated to providing customers with the best
                products and services at competitive prices. We understand the
                importance of delivering value to our customers and have
                developed an aggressive pricing strategy to ensure that they
                receive the best deals possible. Our pricing team works
                tirelessly to negotiate with suppliers and partners to get the
                lowest possible prices, allowing us to pass on the savings to
                our customers.
                <br />
                Our aggressive pricing strategy is not just about offering low
                prices; it is about providing customers with high-quality
                products and services at affordable prices. We are confident
                that our prices are the best in the market, and we back this up
                with our price match guarantee.
              </MKTypography>
            </Fade>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="180px" pb={{ xs: 2, lg: 6 }}>
              <Card
                sx={{
                  p: 2,
                  mx: { xs: 2, lg: 3 },
                  mt: -8,
                  mb: 4,
                  boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  width: "330px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="h2"
                  fontWeight="bold"
                  mb={1}
                  align="center"
                >
                  COLLABORATIVE INNOVATION FOR FABRICS
                </MKTypography>
              </Card>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Fade direction={"right"}>
              <MKTypography variant="body1" color="text.primary">
                We are focused on fostering innovation through collaboration. We
                understand that innovation is the key to staying competitive in
                today&apos;s fast-paced business environment. We work closely
                with our clients to create a collaborative culture to enhance
                creativity. Our team of experts brings a wealth of knowledge and
                experience to the table. We help our clients to use the
                collective knowledge of their team members, partners, and
                stakeholders. Our approach is based on the power of
                understanding the end consumer&apos;s needs and to meet them
                everytime. Whether you are looking to improve your product
                offerings, streamline your operations, or expand into new
                markets, we can help you achieve your goals through innovative
                collaboration.
              </MKTypography>
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
