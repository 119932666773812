import { Link } from "react-router-dom";
import { React, useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Fade } from "react-awesome-reveal";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExampleCard from "./ExampleCard";

// Data
import data from "./data/productBlocksData";

function DesignBlocks() {
  const [animate, setAnimate] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:1000px)");

  useEffect(() => {
    // Trigger animation when component mounts
    setAnimate(true);
  }, []);

  const renderData = data.map(({ title, description, id, items }, index) => (
    <div id={id} key={id}>
      <Grid
        container
        spacing={isSmallScreen ? 2 : 6}
        justifyContent={index % 2 === 0 ? "space-between" : "flex-end"}
        sx={{ mb: isSmallScreen ? 2 : 10 }}
        key={title}
      >
        <Grid
          item
          xs={12}
          lg={6}
          order={isSmallScreen ? 2 : index % 2 === 0 ? 1 : 2}
          className={animate ? "fade-in" : ""}
        >
          <MKTypography
            variant={isSmallScreen ? "h4" : "h1"}
            fontWeight="bold"
            mb={1}
          >
            {title}
          </MKTypography>
          <MKTypography
            variant="body1"
            fontWeight="regular"
            color="secondary"
            mb={1}
            pr={isSmallScreen ? 1 : 2}
          >
            {description}
          </MKTypography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          order={isSmallScreen ? 1 : index % 2 === 0 ? 2 : 1}
          className={animate ? "fade-in" : ""}
        >
          <MKBox
            top={isSmallScreen ? "0px" : "100px"}
            pb={{ xs: 2, lg: 6 }}
            sx={{
              width: "100%",
              maxWidth: "600px",
              marginTop: isSmallScreen ? "50px" : 0,
            }}
          >
            <Grid container spacing={isSmallScreen ? 1 : 3}>
              {items.map(({ image, route }) => {
                const isUrl =
                  image.startsWith("http") || image.startsWith("data");
                const imagePath = isUrl ? image : image;
                return (
                  <Grid
                    item
                    xs={6}
                    md={6}
                    sx={{ mb: isSmallScreen ? 5 : 2 }} // Increase marginBottom for smaller screens
                    key={title}
                  >
                    <Link to={route}>
                      <Fade direction={index % 2 === 0 ? "right" : "left"}>
                        <ExampleCard image={imagePath} display="grid" />
                      </Fade>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </MKBox>
        </Grid>
      </Grid>
    </div>
  ));

  return (
    <MKBox component="section" sx={{ mt: "50px", pt: "50px" }}>
      <Container sx={{ mt: "5px " }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
