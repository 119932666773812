// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import DesignBlocks from "./sections/designBlocks";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation_2.png";
import darkLogo from "assets/images/logo_dark-removebg-preview.png";

function Products() {
  return (
    <>
      <DefaultNavbar
        brand={darkLogo}
        routes={routes}
        // transparent
        black
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="black"
              fontWeight="bold"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Our Products
            </MKTypography>
            <MKTypography
              variant="body1"
              color="black"
              opacity={0.8}
              mt={1}
              mb={3}
            >
              FASHIONING YOUR TODAY, TOMORROW AND FOREVER
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          mt="150px"
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          pb={{ xs: 1, lg: 4 }}
        >
          <MKTypography variant="h2" fontWeight="bold" mb={1}>
            OUR CRAFTED AND CURATED FABRICS
          </MKTypography>
        </MKBox>
        <DesignBlocks sx={{ mt: "100px", pt: "50px" }} />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Products;
