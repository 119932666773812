import jacquard from "assets/images/jacquard.webp";
import polyester from "assets/images/what_we_do.avif";
import cotton from "assets/images/cotton.jpg";
import brasso from "assets/images/brasso.png";
import digital_print from "assets/images/digital_print.jpg";
import embroidery from "assets/images/embroidery.png";

export default [
  {
    title: "COTTON",
    description:
      "Introducing our exquisite range of cotton fabrics, ranging from 40s, 50s, 60s, 80s, 90s, 100s,120s to the finest 2/2 cotton and various other blends. Experience the unmatched softness and breathability that only \
      high-quality cotton and cotton satins can provide. We offer all kinds of cotton digital prints, butas, dobby and cotton jacquards",
    id: "cotton",
    items: [
      {
        image: cotton,
        route: "#cotton",
      },
    ],
  },
  {
    title: "POLYESTERS",
    description:
      "With a spectrum of colors, patterns, and textures, you'll find the perfect fabric for any style or aesthetic. Whether you prefer classic solids, vibrant prints, or delicate florals, we have a wide selection to satisfy your creative cravings.",
    id: "polyester",
    items: [
      {
        image: polyester,
        route: "#polyester",
      },
    ],
  },
  {
    title: "EMBROIDERY",
    description:
      "Our value addition expertise lies in handling versatility, complexity, a vast spectrum of colors, excellence in craftsmanship, cutting edge technology, \
      innovative concepts and the best finishes in the world. We also have an extensive design catalogue to give customers a choice in all kinds of embroideries such as siphli, chickankari butas, sequins etc.",
    id: "embroidery",
    items: [
      {
        image: embroidery,
        route: "#embroidery",
      },
    ],
  },
  {
    title: "DIGITAL PRINT",
    description:
      "imagine being able to bring your unique visions to life on fabric with unparalleled precision and vibrancy. Our digital printing process utilizes advanced techniques, allowing for intricate details, vivid colors, and seamless gradients",
    id: "digital_print",
    items: [
      {
        image: digital_print,
        route: "#digital_print",
      },
    ],
  },
  {
    title: "JACQUARDS",
    description:
      "Jacquards is one of our heritage offerings. We have been the pioneers \
      of jacquards in India since the early 1990s. Our love affair with Jacquards is built \
      upon four key pillars – design, innovation, sustainability, and customer centricity. \
      Today, we power the most iconic ethnic brands across India, Bangladesh and UAE. \
      Taking jacquard’s flair for expression and adding multiple dimensions to it, we are \
      fashioning the fabrics of the past, present and the future.",
    id: "jacquard",
    items: [
      {
        image: jacquard,
        // name: "Page Headers",
        // count: 10,
        route: "#jacquard",
      },
    ],
  },
  {
    title: "BRASSO",
    description:
      "Our Brasso fabrics are loved by men for their elegant designs and comfort, even in the harshest of summers. They are carefully designed and cautiously manufactured to provide the most unique clothing experience using special cotton and polyester blends.",
    id: "brasso",
    items: [
      {
        image: brasso,
        route: "#brasso",
      },
    ],
  },
];
