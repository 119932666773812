import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import image1 from "assets/images/devesh.webp";
import image2 from "assets/images/devesh_dad.webp";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";
import useMediaQuery from "@mui/material/useMediaQuery";

function DesignBlocks() {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger animation when component mounts
    setAnimate(true);
  }, []);
  const isSmallScreen = useMediaQuery("(max-width:1000px)");

  return (
    <MKBox component="section" my={6} py={6}>
      <Container sx={{ mt: 6 }}>
        <Grid
          container
          spacing={6}
          justifyContent="space-around"
          sx={{ mb: 10 }}
        >
          <Grid
            order={isSmallScreen ? 2 : 1}
            item
            xs={12}
            lg={7}
            className={animate ? "fade-in" : ""}
          >
            <MKTypography variant="h1" fontWeight="bold" mb={1}>
              By Devesh Garg, Managing Director.
            </MKTypography>
            <MKTypography
              variant="body1"
              fontWeight="regular"
              color="secondary"
              mb={1}
              pr={2}
            >
              DSM, with its vision and relentless drive, is today a part of the
              burgeoning Indian textile industry, with a consumer market beyond
              the shores of India, and a presence beyond many horizons. Our
              specialty fabrics include all kinds of cottons, jacquards,
              polyesters, digital print, embroidery, brasso, siphli, rotary
              print, butas and other value-added fabrics, made in various blends
              of cotton, polyester, viscose and other such industry standard
              yarns while providing great exclusivity in concepts and designing
              in the various genres of fabrics. As a pioneer in developing a
              large variety of blends and shades, we are consistently adapting
              to the ever-changing requirements of our clients. DSM has grown to
              be one of the leading manufacturers of value-added and specialty
              fabrics in India. We have the capability to accelerate capacity
              creation at the lowest cost in the shortest time. We follow both
              linear and non-linear growth strategies for our business. Our
              resilience is derived from the ability to understand customer
              needs, create differentiated products, invest in enduring
              relationships, reduce cost and enhance quality.
            </MKTypography>
          </Grid>
          <Grid
            order={isSmallScreen ? 1 : 2}
            item
            xs={12}
            lg={5}
            className={animate ? "fade-in" : ""}
          >
            <MKBox
              top="100px"
              pb={{ xs: 2, lg: 6 }}
              sx={{ width: "90%", maxWidth: "800px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                  <Link to="/">
                    <ExampleCard
                      image={image1}
                      pro={false}
                      sx={{ width: "350px", height: "300px" }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={6}
          justifyContent="space-around"
          sx={{ mb: 10 }}
        >
          <Grid
            order={1}
            item
            xs={12}
            lg={5}
            className={animate ? "fade-in" : ""}
          >
            <MKBox
              top="100px"
              pb={{ xs: 2, lg: 6 }}
              sx={{ width: "90%", maxWidth: "800px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                  <Link to="/">
                    <ExampleCard
                      image={image2}
                      pro={false}
                      sx={{ width: "350px", height: "300px" }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
          <Grid
            order={2}
            item
            xs={12}
            lg={7}
            className={animate ? "fade-in" : ""}
          >
            <MKTypography variant="h1" fontWeight="bold" mb={1}>
              By Gopal Garg, CEO, DSM.
            </MKTypography>
            <MKTypography
              variant="body1"
              fontWeight="regular"
              color="secondary"
              mb={1}
              pr={2}
            >
              As part of product development, our company works with leading
              international and domestic brands to develop a range of fabrics in
              line with emerging design trends. Our business has grown rapidly
              on the strength of planned investment in creative addition. We are
              always diversifying our product portfolio with rigorous research
              and development. Our product strategy involves engaging with
              clients in the early stages of product development, thereby
              strengthening their required outcomes. We have set our sights on
              becoming a global textile frontrunner, providing wide spectrum of
              fabric supplies to export houses, big retail chains and other such
              B2B set ups, both domestically and internationally, from specialty
              fabrics to regular fabrics; thereby maximizing value for our
              clients and in turn, becoming their preferred partner.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DesignBlocks;
